<template>
  <div class="password">
      <div class="login-center">
          <div class="login-form">
                <div class="password-title">找回密码</div>
                <div class="password-center">
                    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="0" class="demo-ruleForm">
                        <el-form-item prop="phone">
                            <el-input v-model="ruleForm.phone" auto-complete="off" placeholder="请输入您的手机号"></el-input>
                        </el-form-item>
                        <el-form-item prop="code">
                            <el-input v-model="ruleForm.code" placeholder="请输入验证码" style="width:67%;"></el-input>
                            <el-button class="button-code" :disabled='isDisabled' @click="sendCode">{{buttonText}}</el-button>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input type="password" v-model="ruleForm.password" placeholder="设置密码" show-password></el-input>
                        </el-form-item>
                        <el-form-item prop="passwordAgain">
                            <el-input type="password" v-model="ruleForm.passwordAgain" placeholder="再次输入密码" show-password></el-input>
                        </el-form-item>
                        <el-form-item style="margin-bottom:0;">
                            <el-button ype="primary" @click="submitForm('ruleForm')" style="width:100%;" class="login_button">确 定</el-button>
                        </el-form-item>
                    </el-form>
                </div>
          </div>
      </div>
  </div>
</template>

<script>
import { forgetPassword } from "../../api/login"
import { sendCode } from "../../api/register"
export default {
    data(){
        // 验证手机号是否合法
        let checkTel = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入手机号码"));
            } else if (!this.checkMobile(value)) {
                callback(new Error("手机号码格式不正确"));
            } else {
                callback();
            }
        }
        // 验证码是否为空
        let checkSmscode = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入手机验证码'))
            } else {
                callback()
            }
        }
        //验证密码
        let validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"))
            } else {
                if (this.ruleForm.passwordAgain !== "") {
                    this.$refs.ruleForm.validateField("passwordAgain");
                }
                callback()
            }
        }
        //二次验证密码
        let validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.ruleForm.password) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        }
        return{
            ruleForm: {
                password: "",
                passwordAgain: "",
                phone: "",
                code: ""
            },
            rules: {
                password: [{ validator: validatePass, trigger: 'change' }],
                passwordAgain: [{ validator: validatePass2, trigger: 'change' }],
                phone: [{ validator: checkTel, trigger: 'change' }],
                code: [{ validator: checkSmscode, trigger: 'change' }]
            },
            buttonText: '获取验证码',
            isDisabled: false, // 是否禁止点击发送验证码按钮
            flag: true,
        }
    },
    methods:{
        // 发送验证码
        sendCode () {
            let phone = this.ruleForm.phone
            if(phone == ""){
                this.$message.error('手机号不能为空')
                return false
            }else if(!/^1[23456789]\d{9}$/.test(phone)){
                this.$message.error('手机号格式不正确')
                return false
            }
            // console.log(phone)
            if (this.checkMobile(phone)) {
                sendCode({phone:phone}).then(res => {
                    this.$message.success(res.data.message)
                    let time = 60
                    this.buttonText = '已发送'
                    this.isDisabled = true
                    if (this.flag) {
                        this.flag = false;
                        let timer = setInterval(() => {
                            time--;
                            this.buttonText = time + ' 秒'
                            if (time === 0) {
                                clearInterval(timer);
                                this.buttonText = '重新获取'
                                this.isDisabled = false
                                this.flag = true;
                            }
                        }, 1000)
                    }
                });
            }
        },

        //登录
        submitForm(formName){
            this.$refs.ruleForm.validate(valid => {
                if(valid){
                    forgetPassword(this.ruleForm).then(res=>{
                        if(res.data.code == 20000){
                            this.$router.push("/login");
                            this.$message.success(res.data.message)
                        }else{
                            this.$message.error(res.data.message);
                        } 
                    })
                }
            })
        },

         // 验证手机号
        checkMobile(str) {
            let re = /^1\d{10}$/;
            if (re.test(str)) {
                return true;
            } else {
                return false;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.password{
    .login-center{
        width: 100%;
        height: 100vh;
        background-image: url('../../assets/img/bg.jpg');
        background-repeat: no-repeat;
        background-size:100% 100%;
    }
    .password-title{
        width:100%;
        height:60px;
        background-color:#F8F8F8;
        color:#333333;
        font-size:16px;
        display:flex;
        align-items:center;
        justify-content: center;
    }
    /deep/ .el-form-item{
        margin-bottom:20px;
    }
    /deep/ .el-form-item__content{
        display:flex;
        align-items:center;
        justify-content: space-between;
    }
    .button-code{
        background-color:#123E90;
        color:#ffffff;
        margin-left:10px;
    }
    .password-center{
        padding:20px 45px 0 45px;
        box-sizing: border-box;
    }
    .login_button{
        margin-top:0 !important;
    }
}
</style>